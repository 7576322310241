import React from 'react';
import { Typography, List, ListItem, ListItemText, Modal, Button, Box } from '@mui/material';

interface PoolInfoModalProps {
    show: boolean;
    onHide: () => void;
    pool: string | null;
    children?: React.ReactNode;
    open?: boolean;
}
const PoolInfoModal: React.FC<PoolInfoModalProps> = (props) => {
    const { show, onHide, pool, ...modalProps } = props;
    const { open, ...otherModalProps } = modalProps;
    const getModalTitle = () => {
        switch (pool) {
            case '1':
                return 'Pool 1 Info';
            case '100':
                return 'Pool 100 Info';
            case '250':
                return 'Pool 250 Info';
            case '500':
                return 'Pool 500 Info';
            case '750':
                return 'Pool 750 Info';
            case '1000':
                return 'Pool 1000 Info';
            case '2500':
                return 'Pool 2500 Info';
            case '5000':
                return 'Pool 5000 Info';
            case '7500':
                return 'Pool 7500 Info';
            case '10000':
                return 'Pool 10000 and Angel Pool Info';
            case '10001':
                return 'Pool Spezial Info';
            default:
                return 'Allgemeine Pool Info';
        }
    };

    const getModalContent = () => {
        switch (pool) {
            case '1':
                return `Pool ${pool} Info`;
            case '100':
                return `Stake ${pool} FLR or WFLR to activate this pool and participate in the FTSO lottery twice a week, with a monthly chance of winning 4.5% of the pools FlareDrop.`;
            case '250':
                return `Stake ${pool} FLR or WFLR to activate this pool and participate in the FTSO lottery twice a week, with a monthly chance of winning 4.5% of the pools FlareDrop.`;
            case '500':
                return `Stake ${pool} FLR or WFLR to activate this pool and participate in the FTSO lottery twice a week, with a monthly chance of winning 4.5% of the pools FlareDrop.`;
            case '750':
                return `Stake ${pool} FLR or WFLR to activate this pool and participate in the FTSO lottery twice a week, with a monthly chance of winning 4.5% of the pools FlareDrop.`;
            case '1000':
                return `Stake ${pool} FLR or WFLR to activate this pool and participate in the FTSO lottery twice a week, with a monthly chance of winning 4.5% of the pools FlareDrop.`;
            case '2500':
                return `Stake ${pool} FLR or WFLR to activate this pool and participate in the FTSO lottery twice a week, with a monthly chance of winning 4.5% of the pools FlareDrop.`;
            case '5000':
                return `Stake ${pool} FLR or WFLR to activate this pool and participate in the FTSO lottery twice a week, with a monthly chance of winning 4.5% of the pools FlareDrop.`;
            case '7500':
                return `Stake ${pool} FLR or WFLR to activate this pool and participate in the FTSO lottery twice a week, with a monthly chance of winning 4.5% of the pools FlareDrop.`;
            case '10000':
                return (
                    <>
                        <Typography variant="body1">
                            Stake 10000 FLR or WFLR to activate this pool and participate in the FTSO lottery twice a week, with a monthly chance of winning 4.5% of the pool&rsquo;s FlareDrop.
                        </Typography>
                        <Typography variant="h6">Activate the Angel Pool</Typography>
                        <Typography variant="body1">
                            The Angel Pool operates under its own set of rules and rewards within the larger pool. It is not necessary to be a member of all 9 pools to participate in the Angel Pool. As a member of the Angel Pool, you have an equal chance of winning the monthly rewards as those who are part of the other 8 pools. Moreover, the Angel Pool offers exclusive rewards to its 3 lucky winners each month, as described earlier. Therefore, even if you are not a member of all 9 pools, you can still enjoy the advantages of participating in the FTSO Lottery through the Angel Pool.
                        </Typography>
                        <Typography variant="body1">
                            Three places will be drawn from among the 500 members each month:
                        </Typography>
                        <List>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                <ListItemText primary="The 1st winner out of a maximum of 500 will receive 3%" />
                            </ListItem>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                <ListItemText primary="The 2nd winner out of a maximum of 500 will receive 1.5%" />
                            </ListItem>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                <ListItemText primary="The 3rd winner out of a maximum of 500 will receive 0.5%" />
                            </ListItem>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                <ListItemText primary="Of the monthly total Flare Drop Delegation rewards (from all 9 pools) of the FTSO Lottery as the prize." />
                            </ListItem>
                        </List>
                        <Typography variant="body1">
                            It&rsquo;s important to note that the pools are growing every month, meaning the possible winnings from the FTSO Lottery are also increasing.
                        </Typography>
                        <Typography variant="body1">
                            Members of the Angel Pool remain active and locked until January 30, 2026.
                        </Typography>
                    </>
                );
            case '10001':
                return 'Pool Spezial Content';
            default:
                return 'Allgemeine Pool Content';
        }
    };

    return (
        <Modal open={show} onClose={onHide} {...otherModalProps}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    maxWidth: '600px',
                    width: '100%',
                    backgroundColor: '#fff',
                    color: '#000',
                    borderRadius: '8px',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '1rem',
                        borderBottom: '1px solid #ddd',
                    }}
                >
                    <Typography variant="h6">{getModalTitle()}</Typography>
                    <Button onClick={onHide} style={{ border: 'none', background: 'none', cursor: 'pointer' }}>
                        &times;
                    </Button>
                </Box>
                <Box sx={{ padding: '1rem' }}>{getModalContent()}</Box>
            </Box>
        </Modal>
    );
};

export default PoolInfoModal;