'use client';

import Image from 'next/image';

const HomeNotConected = () => {
    return (
        <>
            <div className="relative isolate overflow-hidden bg-gray-900">
                <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
                    <svg
                        className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
                        viewBox="0 0 1155 678"
                    >
                        <path
                            fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
                            fillOpacity=".2"
                            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                        />
                        <defs>
                            <linearGradient
                                id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
                                x1="1155.49"
                                x2="-78.208"
                                y1=".177"
                                y2="474.645"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#9089FC" />
                                <stop offset={1} stopColor="#FF80B5" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
                <div className="px-6 lg:px-8">
                    <div className="mx-auto max-w-5xl py-20 sm:py-20 lg:py-20">
                        <div className="text-center">
                            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                                Welcome to FTSOLottery
                            </h1>
                            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-4xl">
                                Your Best Choice for Maximizing Profits
                            </h2>
                            <p className="mt-6 text-lg leading-8 text-gray-300">
                                The <strong className="font-bold">FTSOLottery</strong> offers a secure and transparent platform for staking <strong className="font-bold">FLR</strong> or <strong className="font-bold">WFLR</strong>, providing participants with a 10% chance to win a share of <strong className="font-bold">FTSO rewards</strong> across multiple pools and maximize their earnings.
                            </p>
                            <p className="mt-6 text-lg leading-8 text-gray-300">
                                With up to <strong className="font-bold">90 monthly opportunities</strong> to win through staking, there is no risk of losing money, making it distinct from traditional lotteries. Rest assured that you will never end up with less than what you initially had.
                            </p>
                            <p className="mt-6 text-lg leading-8 text-gray-300">
                                Our strategy involves allocating pools to the best performing <strong className="font-bold">FTSOs</strong>, with the goal of maximizing profit potential. Remember, the more players we have, the bigger the winnings will be for everyone. So, spread the <strong className="font-bold">FTSO Lottery</strong> and let&apos;s all grow together!
                            </p>
                            <p className="mt-6 text-lg leading-8 text-gray-300">
                                We genuinely wish you the best of luck in your{" "}
                                <strong className="font-bold">FTSOLottery journey!</strong>
                            </p>

                            <div className="mt-6">
                                <nav className="flex justify-center">
                                    <ul className="grid grid-cols-2 sm:flex sm:flex-wrap sm:space-x-4">
                                        <li>
                                            <a href="#winning_chances" className="text-2xl text-decoration-underline text-white font-bold">Attractive Chances</a>
                                        </li>
                                        <li>
                                            <a href="#boosting" className="text-2xl text-decoration-underline text-white font-bold">Boosting</a>
                                        </li>
                                        <li>
                                            <a href="#prizelist" className="text-2xl text-decoration-underline text-white font-bold">Price List</a>
                                        </li>
                                        <li>
                                            <a href="#angelpool" className="text-2xl text-decoration-underline text-white font-bold">Angel Pool</a>
                                        </li>
                                        <li>
                                            <a href="#guarantee" className="text-2xl text-decoration-underline text-white font-bold">Guarantee</a>
                                        </li>
                                        <li>
                                            <a href="#no-costs" className="text-2xl text-decoration-underline text-white font-bold">No Costs</a>
                                        </li>
                                        <li>
                                            <a href="#security" className="text-2xl text-decoration-underline text-white font-bold">Security</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <div className="mt-6">
                                <nav className="flex justify-center">
                                    <ul className="grid grid-cols-2 sm:flex sm:flex-wrap sm:space-x-4">
                                        <li>
                                            <a href="#smartphone" className="text-2xl text-decoration-underline text-white font-bold">If you are using a Smartphone</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
                    <svg
                        className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
                        viewBox="0 0 1155 678"
                    >
                        <path
                            fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
                            fillOpacity=".2"
                            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                        />
                        <defs>
                            <linearGradient
                                id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
                                x1="1155.49"
                                x2="-78.208"
                                y1=".177"
                                y2="474.645"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#9089FC" />
                                <stop offset={1} stopColor="#FF80B5" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
            </div>


            <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4" id="winning_chances">
                <div className="flex lg:flex-row flex-col lg:gap-8 sm:gap-10 gap-12">
                    <div className="w-full lg:w-6/12">
                        <h2 className="w-full font-bold lg:text-4xl text-3xl lg:leading-10 leading-9">Attractive chances of winning
                        </h2>
                        <p className="font-normal text-base leading-6 text-gray-600 mt-6 mb-4">We ensure that our operations remain up to date, constantly monitoring and distributing the pools across the best-performing FTSOs. This approach allows us to maximize profit distribution and provide participants with the highest possible returns.</p>

                    </div>

                    <div className="w-full lg:w-6/12">
                        <Image className="lg:block hidden w-full rounded" src="/images/winning_chances.jpg" alt="people discussing on board" width={800} height={500} />
                        <Image className="lg:hidden sm:block hidden w-full rounded" src="/images/winning_chances.jpg" alt="people discussing on board" width={500} height={300} />
                        <Image className="sm:hidden block w-full rounded" src="/images/winning_chances.jpg" alt="people discussing on board" width={300} height={300} />
                    </div>
                </div>
            </div>


            <div className="relative isolate overflow-hidden bg-gray-900" id="boosting">
                <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
                    <svg
                        className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
                        viewBox="0 0 1155 678"
                    >
                        <path
                            fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
                            fillOpacity=".2"
                            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                        />
                        <defs>
                            <linearGradient
                                id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
                                x1="1155.49"
                                x2="-78.208"
                                y1=".177"
                                y2="474.645"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#9089FC" />
                                <stop offset={1} stopColor="#FF80B5" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
                <div className="px-6 lg:px-8">
                    <div className="mx-auto max-w-5xl py-20 sm:py-20 lg:py-20">
                        <div className="text-center">
                            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                                Boosting Your Monthly Winning Chances with Our FTSO Lottery Pools!
                            </h1>
                            <p className="mt-6 text-lg leading-8 text-gray-300">
                                Unlocking Additional Qualification Benefits for Joining Multiple Active Pools in the FTSO Lottery, including 0.5% for having 4 active pools, 1% for having 5 or more active pools, 1.5% for having 6 or more active pools, 2% for having 7 or more active pools, 2.5% for having 8 or more active pools, and 3% for having 9 active pools, all based on the monthly Total Flare Drop distribution rewards from all 9 pools.
                            </p>
                            <p className="mt-6 text-lg leading-8 text-gray-300">
                                There are a total of <strong>87 winning chances every month</strong> if you have 9 active pools in the FTSO Lottery. This includes 72 chances to win the delegation rewards (9 pools x 8 epochs), 9 chances to win 4.5% of the monthly Flare Drop of each active pool, and 6 chances to win a percentage of the total monthly Flare Drop distribution rewards (from all 9 pools) of the FTSO Lottery.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
                    <svg
                        className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
                        viewBox="0 0 1155 678"
                    >
                        <path
                            fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
                            fillOpacity=".2"
                            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                        />
                        <defs>
                            <linearGradient
                                id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
                                x1="1155.49"
                                x2="-78.208"
                                y1=".177"
                                y2="474.645"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#9089FC" />
                                <stop offset={1} stopColor="#FF80B5" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
            </div>

            <div className="pb-16" style={{ fontFamily: '"Lato", sans-serif' }} id="prizelist">
                <>
                    <section className="max-w-8xl mx-auto container bg-white pt-16">
                        <div>
                            <div role="contentinfo" className="flex items-center flex-col px-4">
                                <p tabIndex={0} className="focus:outline-none uppercase text-sm text-center text-gray-600 leading-4">Prize List</p>
                                <p tabIndex={0} className="focus:outline-none text-4xl lg:text-4xl font-extrabold text-center leading-10 text-gray-800 lg:w-5/12 md:w-9/12 pt-4">Up to 90 Monthly Opportunities to Win</p>
                            </div>
                            <div tabIndex={0} aria-label="group of cards" className="focus:outline-none mt-20 flex flex-wrap justify-center gap-10 px-4">
                                <div tabIndex={0} aria-label="card 1" className="focus:outline-none flex sm:w-full md:w-5/12 pb-20">
                                    <div className="w-20 h-20 relative mr-5">
                                        <div className="absolute top-0 right-0 bg-indigo-100 rounded w-16 h-16 mt-2 mr-1" />
                                        <div className="absolute text-white bottom-0 left-0 bg-violet-950 rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                 strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"/>
                                            </svg>

                                        </div>
                                    </div>
                                    <div className="w-10/12">
                                        <h2 tabIndex={0} className="focus:outline-none text-lg font-bold leading-tight text-gray-800">FTSO Rewards</h2>
                                        <p tabIndex={0} className="focus:outline-none text-base text-gray-600 leading-normal pt-2">72 chances (9 pools x 8 epochs) to win per month (if 9 active pools are held)</p>
                                    </div>
                                </div>
                                <div tabIndex={0} aria-label="card 2" className="focus:outline-none flex sm:w-full md:w-5/12 pb-20">
                                    <div className="w-20 h-20 relative mr-5">
                                        <div className="absolute top-0 right-0 bg-indigo-100 rounded w-16 h-16 mt-2 mr-1" />
                                        <div className="absolute text-white bottom-0 left-0 bg-violet-950 rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                 strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                            </svg>

                                        </div>
                                    </div>
                                    <div className="w-10/12">
                                        <h2 tabIndex={0} className="focus:outline-none text-lg font-semibold leading-tight text-gray-800">Monthly Flare Drop Rewards</h2>
                                        <div tabIndex={0} className="focus:outline-none text-base text-black leading-normal pt-2">
                                            <ul className="list-disc pl-6 text-black">
                                                <li className="text-black">4.5% of the monthly Flare Drop of the respective active pool (9 times per month)</li>
                                                <li className="text-black">A percentage of the total monthly Flare Drop (from all 9 pools) of the FTSO Lottery (6 times per month)</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div tabIndex={0} aria-label="card 3" className="focus:outline-none flex sm:w-full md:w-5/12 pb-20">
                                    <div className="w-20 h-20 relative mr-5">
                                        <div className="absolute top-0 right-0 bg-indigo-100 rounded w-16 h-16 mt-2 mr-1" />
                                        <div className="absolute text-white bottom-0 left-0 bg-violet-950 rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                 strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M12 3.75v16.5M2.25 12h19.5M6.375 17.25a4.875 4.875 0 004.875-4.875V12m6.375 5.25a4.875 4.875 0 01-4.875-4.875V12m-9 8.25h16.5a1.5 1.5 0 001.5-1.5V5.25a1.5 1.5 0 00-1.5-1.5H3.75a1.5 1.5 0 00-1.5 1.5v13.5a1.5 1.5 0 001.5 1.5zm12.621-9.44c-1.409 1.41-4.242 1.061-4.242 1.061s-.349-2.833 1.06-4.242a2.25 2.25 0 013.182 3.182zM10.773 7.63c1.409 1.409 1.06 4.242 1.06 4.242S9 12.22 7.592 10.811a2.25 2.25 0 113.182-3.182z"/>
                                            </svg>

                                        </div>
                                    </div>
                                    <div className="w-10/12">
                                        <h2 tabIndex={0} className="focus:outline-none text-lg font-semibold leading-tight text-gray-800">Angel Pool Rewards</h2>
                                        <div className="focus:outline-none text-base text-black leading-normal pt-2">
                                            <ul className="list-disc pl-6">
                                                <li className="text-black">1st place out of max. 500: 3% of the monthly total Flare Drop (from all 9 pools)</li>
                                                <li className="text-black">2nd place out of max. 500: 1.5% of the monthly total Flare Drop (from all 9 pools)</li>
                                                <li className="text-black">3rd place out of max. 500: 0.5% of the monthly total Flare Drop (from all 9 pools)</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div tabIndex={0} aria-label="card 4" className="focus:outline-none flex sm:w-full md:w-5/12 pb-20">
                                    <div className="w-20 h-20 relative mr-5">
                                        <div className="absolute top-0 right-0 bg-indigo-100 rounded w-16 h-16 mt-2 mr-1" />
                                        <div className="absolute text-white bottom-0 left-0 bg-violet-950 rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">

                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                 strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z"/>
                                            </svg>


                                        </div>
                                    </div>
                                    <div className="w-10/12">
                                        <h2 tabIndex={0} className="focus:outline-none text-lg font-semibold leading-tight text-gray-800">Including the Angel Pool</h2>
                                        <p tabIndex={0} className="focus:outline-none text-base text-gray-600 leading-normal pt-2">there are a total of 90 chances to win per month (87 from the 9 regular pools and 3 from the Angel Pool).</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            </div>

            <div className="relative isolate overflow-hidden bg-gray-900" id="angelpool">
                <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
                    <svg
                        className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
                        viewBox="0 0 1155 678"
                    >
                        <path
                            fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
                            fillOpacity=".2"
                            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                        />
                        <defs>
                            <linearGradient
                                id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
                                x1="1155.49"
                                x2="-78.208"
                                y1=".177"
                                y2="474.645"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#9089FC" />
                                <stop offset={1} stopColor="#FF80B5" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
                <div className="px-6 lg:px-8">
                    <div className="mx-auto max-w-5xl py-20 sm:py-20 lg:py-20">
                        <div className="text-center">
                            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                                Angel Pool
                            </h1>
                            <p className="mt-6 text-lg leading-8 text-gray-300">
                                The Angel Pool operates under its own set of rules and rewards within the larger pool. It is not necessary to be a member of all 9 pools to participate in the Angel Pool. As a member of the Angel Pool, you have an equal chance of winning the monthly rewards as those who are part of the other 8 pools. Moreover, the Angel Pool offers exclusive rewards to its 3 lucky winners each month, as described earlier. Therefore, even if you are not a member of all 9 pools, you can still enjoy the advantages of participating in the FTSO Lottery through the Angel Pool.

                            </p>

                        </div>
                    </div>
                </div>
                <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
                    <svg
                        className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
                        viewBox="0 0 1155 678"
                    >
                        <path
                            fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
                            fillOpacity=".2"
                            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                        />
                        <defs>
                            <linearGradient
                                id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
                                x1="1155.49"
                                x2="-78.208"
                                y1=".177"
                                y2="474.645"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#9089FC" />
                                <stop offset={1} stopColor="#FF80B5" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
            </div>

            <div className="bg-white">
                <div className="mx-auto max-w-7xl py-8 px-6 sm:py-8 lg:px-8">
                    <div className="text-center">
                        <p className="mt-1 text-2xl font-bold tracking-tight sm:text-5xl lg:text-6xl">
                            Join Our Angel Pool Today and Secure Your Spot Among 500 Lucky Members!
                        </p>
                        <p className="mx-auto mt-5">Only 500 members can enter the Angel Pool!</p>
                        <p className="mx-auto">Each month, three winners will be drawn from among the 500 members.</p>

                        <p className="mx-auto mt-5">The 1st winner out of a maximum of 500 will receive 3%,</p>
                        <p className="mx-auto">The 2nd winner out of a maximum of 500 will receive 1.5%,</p>
                        <p className="mx-auto">and the 3rd winner out of a maximum of 500 will receive 0.5%</p>
                        <p className="mx-auto mt-2">of the monthly total Flare Drop Delegation rewards (from all 9 pools) of the FTSO Lottery as the prize.</p>

                    </div>
                </div>
            </div>

            <div className="relative isolate overflow-hidden bg-gray-900" id="guarantee">
                <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
                    <svg
                        className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
                        viewBox="0 0 1155 678"
                    >
                        <path
                            fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
                            fillOpacity=".2"
                            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                        />
                        <defs>
                            <linearGradient
                                id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
                                x1="1155.49"
                                x2="-78.208"
                                y1=".177"
                                y2="474.645"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#9089FC" />
                                <stop offset={1} stopColor="#FF80B5" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
                <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4">
                    <div className="flex lg:flex-row flex-col lg:gap-8 sm:gap-10 gap-12">
                        <div className="w-full lg:w-6/12">
                            <Image className="lg:block hidden w-full rounded" src="/images/Guarantee2.jpg" alt="people discussing on board" width={800} height={500} />
                            <Image className="lg:hidden sm:block hidden w-full rounded" src="/images/Guarantee2.jpg" alt="people discussing on board" width={500} height={300} />
                            <Image className="sm:hidden block w-full rounded" src="/images/Guarantee2.jpg" alt="people discussing on board" width={300} height={300} />
                        </div>
                        <div className="w-full lg:w-6/12 bg-gray-500 bg-opacity-20 rounded">
                            <h2 className="w-full font-bold lg:text-4xl text-3xl lg:leading-10 leading-9 text-white ml-4 mt-4 mr-4">Guarantee</h2>
                            <p className="font-normal text-base leading-6 mt-6 text-white ml-4 mr-4 mb-4">We ensure that our operations remain up to date, constantly monitoring and distributing the pools across the best-performing FTSOs. This approach allows us to maximize profit distribution and provide participants with the highest possible returns.</p>
                        </div>
                    </div>
                </div>
                <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
                    <svg
                        className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
                        viewBox="0 0 1155 678"
                    >
                        <path
                            fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
                            fillOpacity=".2"
                            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                        />
                        <defs>
                            <linearGradient
                                id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
                                x1="1155.49"
                                x2="-78.208"
                                y1=".177"
                                y2="474.645"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#9089FC" />
                                <stop offset={1} stopColor="#FF80B5" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
            </div>

            <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4" id="no-costs">
                <div className="flex lg:flex-row flex-col lg:gap-8 sm:gap-10 gap-12">

                    <div className="w-full lg:w-6/12 ">
                        <h2 className="w-full font-bold lg:text-4xl text-3xl lg:leading-10 leading-9">No Costs
                        </h2>
                        <p className="font-normal text-base leading-6 text-gray-600 mt-6 mb-4">In FTSOLottery, when you stake your FLR or WFLR, there are no expenses or costs involved. This sets us apart from traditional lotteries, where you risk losing your money if you don&apos;t win. We prioritize the safety of your assets, ensuring that you will never end up with less than what you initially had.</p>

                    </div>
                    <div className="w-full lg:w-6/12">

                        <Image className="lg:block hidden w-full rounded" src="/images/no_cost.jpg" alt="people discussing on board" width={800} height={500} />
                        <Image className="lg:hidden sm:block hidden w-full rounded" src="/images/no_cost.jpg" alt="people discussing on board" width={500} height={300} />
                        <Image className="sm:hidden block w-full rounded" src="/images/no_cost.jpg" alt="people discussing on board" width={300} height={300} />
                    </div>
                </div>
            </div>


            <div className="relative isolate overflow-hidden bg-gray-900" id="security">
                <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
                    <svg
                        className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
                        viewBox="0 0 1155 678"
                    >
                        <path
                            fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
                            fillOpacity=".2"
                            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                        />
                        <defs>
                            <linearGradient
                                id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
                                x1="1155.49"
                                x2="-78.208"
                                y1=".177"
                                y2="474.645"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#9089FC" />
                                <stop offset={1} stopColor="#FF80B5" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
                <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4">
                    <div className="flex lg:flex-row flex-col lg:gap-8 sm:gap-10 gap-12">

                        <div className="w-full lg:w-6/12 bg-gray-500 bg-opacity-20 rounded">
                            <Image className="lg:block hidden w-full rounded" src="/images/security3.jpg" alt="people discussing on board" width={800} height={600} />
                            <Image className="lg:hidden sm:block hidden w-full rounded" src="/images/security3.jpg" alt="people discussing on board" width={500} height={400} />
                            <Image className="sm:hidden block w-full rounded" src="/images/security3.jpg" alt="people discussing on board" width={300} height={300} />

                        </div>
                        <div className="w-full lg:w-6/12 bg-gray-500 bg-opacity-20 rounded">
                            <h2 className="w-full font-bold lg:text-4xl text-3xl lg:leading-10 leading-9 text-white ml-4 mt-4 mr-4">Security
                            </h2>
                            <p className="font-normal text-base leading-6 mt-6 text-white ml-4 mr-4 mb-4">To ensure a secure connection to FTSOLottery, we have implemented WalletConnect v2.0. We recommend using MetaMask, Bifrost, SolidiFi, or other Web3 wallets as reliable options for storing and connecting with the website to participate using FLR or WFLR. If you usually connect via Bifrost, SolidiFi, or other Web3 wallets you can simply click on the MetaMask icon to connect to your wallet seamlessly.</p>
                            <p className="font-normal text-base leading-6 mt-6 text-white ml-4 mr-4 mb-4">We want to emphasize that we will NEVER ask for your email address or private key. Safeguarding your security and privacy is our top priority. </p>
                            <p className="font-normal text-base leading-6 mt-6 text-white ml-4 mr-4 mb-4">If you have any concerns, please reach out to us via Twitter or by using the contact form on our website. </p>
                            <p className="font-normal text-base leading-6 mt-6 text-white ml-4 mr-4 mb-4">We place great importance on ensuring the utmost security and privacy standards, which is why we utilize Web3 connections.</p>
                        </div>
                    </div>
                </div>
                <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
                    <svg
                        className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
                        viewBox="0 0 1155 678"
                    >
                        <path
                            fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
                            fillOpacity=".2"
                            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                        />
                        <defs>
                            <linearGradient
                                id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
                                x1="1155.49"
                                x2="-78.208"
                                y1=".177"
                                y2="474.645"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#9089FC" />
                                <stop offset={1} stopColor="#FF80B5" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
            </div>



            <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4" id="smartphone">
                <h3 className="text-center font-semibold lg:text-5xl text-xl lg:leading-6 leading-5 text-gray-800 mt-6">If you are using a Smartphone</h3>
                <div className="relative mt-24">
                    <div className="grid sm:grid-cols-3 grid-cols-2 sm:gap-8 gap-4">
                        <div className="z-20 w-12 h-12 bg-gray-800 rounded-full flex justify-center items-center">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 5V21" stroke="white" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M19 5V14" stroke="white" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M5 4.99984C5.93464 4.08371 7.19124 3.57056 8.5 3.57056C9.80876 3.57056 11.0654 4.08371 12 4.99984C12.9346 5.91598 14.1912 6.42913 15.5 6.42913C16.8088 6.42913 18.0654 5.91598 19 4.99984" stroke="white" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M5 14.0001C5.93464 13.084 7.19124 12.5708 8.5 12.5708C9.80876 12.5708 11.0654 13.084 12 14.0001C12.9346 14.9162 14.1912 15.4294 15.5 15.4294C16.8088 15.4294 18.0654 14.9162 19 14.0001" stroke="white" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>

                        <svg className="z-20" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="24" cy="24" r="24" fill="#1F2937" />
                            <path d="M26 15V19C26 19.2652 26.1054 19.5196 26.2929 19.7071C26.4804 19.8946 26.7348 20 27 20H31" stroke="white" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M31 30V31C31 31.5304 30.7893 32.0391 30.4142 32.4142C30.0391 32.7893 29.5304 33 29 33H19C18.4696 33 17.9609 32.7893 17.5858 32.4142C17.2107 32.0391 17 31.5304 17 31V30" stroke="white" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M30 26H33M15 26H18H15ZM22.5 26H25.5H22.5Z" stroke="white" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M17 22V17C17 16.4696 17.2107 15.9609 17.5858 15.5858C17.9609 15.2107 18.4696 15 19 15H26L31 20V22" stroke="white" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>

                        <svg className="z-20 sm:block hidden" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="24" cy="24" r="24" fill="#1F2937" />
                            <path d="M21 23C23.2091 23 25 21.2091 25 19C25 16.7909 23.2091 15 21 15C18.7909 15 17 16.7909 17 19C17 21.2091 18.7909 23 21 23Z" stroke="white" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M15 33V31C15 29.9391 15.4214 28.9217 16.1716 28.1716C16.9217 27.4214 17.9391 27 19 27H23C24.0609 27 25.0783 27.4214 25.8284 28.1716C26.5786 28.9217 27 29.9391 27 31V33" stroke="white" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M28 15.1301C28.8604 15.3504 29.623 15.8508 30.1676 16.5524C30.7122 17.254 31.0078 18.117 31.0078 19.0051C31.0078 19.8933 30.7122 20.7562 30.1676 21.4578C29.623 22.1594 28.8604 22.6598 28 22.8801" stroke="white" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M33 33.0001V31.0001C32.9949 30.1173 32.6979 29.2609 32.1553 28.5645C31.6126 27.8682 30.8548 27.3708 30 27.1501" stroke="white" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    <hr className="z-10 absolute top-2/4 w-full bg-gray-200" />
                </div>
                <div className="grid sm:grid-cols-3 grid-cols-2 sm:gap-8 gap-4">
                    <div>
                        <p className="font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800 mt-6">Open Web3 Wallet</p>
                        <p className="font-normal text-base leading-6 text-gray-600 mt-6">Please use the browser of your web3-enabled wallet <br/>(e.g., MetaMask, Bifrost, SolidiFi) </p>
                    </div>
                    <div>
                        <p className="font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800 mt-6">Open Web3 Browser</p>
                        <p className="font-normal text-base leading-6 text-gray-600 mt-6">Add https://FTSOLottery.xyz  and Add Bookmark!</p>
                    </div>
                    <div>
                        <p className="font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800 mt-6">Connect Wallet</p>
                        <p className="font-normal text-base leading-6 text-gray-600 mt-6">Simply click &ldquo;Connect Wallet&ldquo; and then click on the MetaMask icon to connect seamlessly.</p>
                    </div>
                </div>
            </div>





        </>
    );
};

export default HomeNotConected;
